import React, { Component } from 'react'
import Skill from '../Skill'

class SkillHeading extends Component {
    render() {
        return(
            <React.Fragment>
                <div className="row" style={{ marginTop: '20px' }}>
                    <div className="col-xs-12 col-md-6 col-lg-4">
                        <h3 className="ck-subheading-about">
                            Web Development
                        </h3>
                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-8">
                        <Skill
                            skill="HTML"
                            progress="90%"
                        />
                        <Skill
                            skill="CSS"
                            progress="90%"
                        />
                        <Skill
                            skill="Sass"
                            progress="80%"
                        />
                        <Skill
                            skill="Bootstrap"
                            progress="80%"
                        />
                        <Skill
                            skill="Material UI"
                            progress="80%"
                        />
                        <Skill
                            skill="Semantic UI"
                            progress="80%"
                        />
                        <Skill
                            skill="JavaScript"
                            progress="80%"
                        />
                        <Skill
                            skill="NodeJS"
                            progress="80%"
                        />
                        <Skill
                            skill="ExpressJS"
                            progress="80%"
                        />
                        <Skill
                            skill="RESTful APIs"
                            progress="80%"
                        />
                        <Skill
                            skill="jQuery"
                            progress="80%"
                        />
                        <Skill
                            skill="Mongoose"
                            progress="80%"
                        />
                        <Skill
                            skill="MongoDB"
                            progress="80%"
                        />
                        <Skill
                            skill="ReactJS"
                            progress="80%"
                        />
                        <Skill
                            skill="ReduxJS"
                            progress="80%"
                        />
                        <Skill
                            skill="PassportJS"
                            progress="80%"
                        />
                        <Skill
                            skill="Gatsby"
                            progress="80%"
                        />
                        <Skill
                            skill="GraphQL"
                            progress="70%"
                        />
                        <Skill
                            skill="Firebase"
                            progress="70%"
                        />
                    </div>
                </div>
                <div className="row" style={{ marginTop: '40px' }}>
                    <div className="col-xs-12 col-md-6 col-lg-4">
                        <h3 className="ck-subheading-about">
                            Blockchain Development
                        </h3>
                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-8">
                        <Skill
                            skill="Solidity"
                            progress="70%"
                        />
                        <Skill
                            skill="Ethereum Development"
                            progress="60%"
                        />
                        <Skill
                            skill="Cryptocurrency"
                            progress="80%"
                        />
                        <Skill
                            skill="Smart Contracts"
                            progress="60%"
                        />
                        <Skill
                            skill="Decentralised Apps"
                            progress="25%"
                        />
                    </div>
                </div>
                <div className="row" style={{ marginTop: '40px' }}>
                    <div className="col-xs-12 col-md-6 col-lg-4">
                        <h3 className="ck-subheading-about">
                            Programming Languages
                        </h3>
                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-8">
                        <Skill
                            skill="Python"
                            progress="80%"
                        />
                        <Skill
                            skill="C++"
                            progress="70%"
                        />
                        <Skill
                            skill="C"
                            progress="60%"
                        />
                        <Skill
                            skill="Java"
                            progress="60%"
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SkillHeading