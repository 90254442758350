import React, { Component } from 'react'

class SummarySection extends Component {
    render() {
        return(
            <div className="container ck-container-about">
                <h2 className="ck-heading-about">
                    About Me
                </h2>
                <p className="ck-about-para">
                    Hi, I'm Amol. I'm a final year undergraduate from National Institute of Technology, Kurukshetra. I'm a self taught Full Stack Developer with the primary focus being MERN stack along with Gatsby. I've built websites ranging from single page React websites to complex websites like E-Commerce and Realtime Messaging. All of my websites have excellent Lighthouse Scores and are well optimised for mobile and desktop users.
                </p>
                <p className="ck-about-para">
                    I'm currently open for job opportunities in the field of Full Stack Web Development, preferably MERN Stack Development.
                </p>
            </div>    
        )
    }
}

export default SummarySection