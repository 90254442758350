import React, { Component } from 'react'
import { Helmet } from "react-helmet"
import Header from '../components/Header'
import SummarySection from '../components/About/SummarySection'
import SkillHeading from '../components/About/SkillHeading'
import Footer from '../components/Footer'

class About extends Component {
    render() {
        return(
            <React.Fragment>
                <Helmet
                    htmlAttributes={{
                        lang: 'en',
                    }}
                >
                    <meta charSet="utf-8" />
                    <title>Amol Garhwal | About</title>
                    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;800;900&display=swap"/>
                    <meta name="description" 
                    content="This page contains information about me and my technical skills." />
                </Helmet>
                <Header selected="about" />
                <SummarySection />
                <div className="container ck-container-skills">
                    <h2 className="ck-heading-about">
                        Technical Skills
                    </h2>
                    <SkillHeading />
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default About