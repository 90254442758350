import React, { Component } from 'react'

class Skill extends Component {
    render() {
        return(
            <div className="ck-skill">
                <h4 className="ck-skill-title">
                    {this.props.skill}
                </h4>
                <div className="progress" style={{ borderRadius: '50px', backgroundColor: '#222' }}>
                    <div
                        className="progress-bar bg-success ck-skill-bar"
                        role="progressbar"
                        aria-label={`${this.props.skill} with ${this.props.progress} knowledge`}
                        style={{width: `${this.props.progress}` }}
                    >
                    </div>
                </div>
            </div>
        )
    }
}

export default Skill